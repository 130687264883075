import axios from 'axios'

import {
  Actions,
  pendingAction,
  registeredAction,
  errorAction
} from './actions.types'
import { code } from '../reducer/index.types'
import { dispatchFn } from '../context/appContext'

export const pending = (): pendingAction => ({
  type: Actions.PENDING
})

export const registered = (): registeredAction => ({
  type: Actions.REGISTER_USER
})

export const error = (error: code): errorAction => ({
  type: Actions.REGISTER_ERROR,
  error
})

export const register = async (email: string, dispatch: dispatchFn) => {
  dispatch(pending())
  await axios.post('https://5fr8dia1t0.execute-api.us-east-1.amazonaws.com/dev/send-email',{
    "to": email
  })
    .then(() => dispatch(registered()))
    .catch(({response}) => {
      const { data } = response
      dispatch(error(data))
    })
}
