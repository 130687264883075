import React, { useState } from 'react'

import { Props } from './Form.types'

const Form: React.FC<Props> = ({
  submit,
  submitting
}) => {
  const [email, setEmail] = useState('')

  return (
    <form
      className='form-row mb-4'
      onSubmit={(e) => {
        e.preventDefault()
        submit(email)
      }}
    >
      <div className='form-group col-md-7'>
        <label className='sr-only'>Password</label>
        <input
          required
          type='email'
          className='form-control'
          placeholder='Enter your email address'
          onChange={ (e) => setEmail(e.target.value)}
        />
      </div>
      <div className='form-group col-md-5'>
        <button className='btn btn-primary btn-block' type='submit' disabled={submitting}>
          <span className={`spinner-border spinner-border-sm ${!submitting && 'd-none'}`} role='status' aria-hidden='true' />
          Notify me
        </button>
      </div>
    </form>
  )
}

export default Form
