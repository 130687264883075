import React from 'react';
import { ErrorProps } from '../../reducer/index.types'

const Error: React.FC<ErrorProps> = ({ error }) => {
  const code = error?.code ? error.code : 0
  return (
    <div className='alert alert-danger mt-2' role='alert'>
      { code === 11000 ?
        'The email entered has already been registered. Please try again with another one.'
        : 'An error has occurred please try again.'
      }
    </div>
  )
}

export default Error
