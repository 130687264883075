import React from 'react'
import ReactGA from "react-ga4";

import './App.css'
import AppProvider from './context/appContext'
import Main from './component/main/Main'

ReactGA.initialize("G-210XBM3YXM");

const App: React.FC = () =>
  <AppProvider>
    <div className='container text-dark'>
      <Main/>
    </div>
    <footer className='text-light h6'><small>© Findaproperty.mu Ltd, 2020</small></footer>
  </AppProvider>


export default App
