import React, { useContext } from 'react'
import { SocialIcon } from 'react-social-icons'
import Form from '../Form/Form'
import { AppContext } from '../../context/appContext'
import Error from '../Error/Error'
import { register } from '../../actions/actions'

const Main: React.FC = () => {
  const {
    dispatch,
    state: {
      registered,
      error,
      hasError,
      submitting
    }
  } = useContext(AppContext)

  const submit = (email: string) => {
    register(email, dispatch)
  }

  return (
    <main className='row'>
      <div className='col' />
        <div className='col-md-6 col-sm-8 mt-4'>
          <div className='text-center mb-5'>
            <img src='./img/logo.svg' alt='Findaproperty search for houses and flats for sale and rent' width='200px' className='text-center' />
          </div>
          <p>We are a unique real-estate portal for properties and land in Mauritius. Whether you're ready to sell, buy or looking for answers, we'll guide you with data and expertise specific to your area.</p>
          <p>Do you want to learn how you can sell or buy a property at the best price in the least amount of time? </p>
          <p className='mb-5'>Visit our <a className='font-weight-bold text-dark' href='https://www.findaproperty.mu/news/' title='Find out about the properties market in mauritius'>blog</a> for latest articles about properties in mauritius. Contact us at <a href="mailto:gerald@findaproperty.mu" className='text-dark font-weight-bold'>gerald@findaproperty.mu</a> </p>
          { hasError && <Error error={error} />}
          { registered ?
            <div className='alert alert-success' role='alert'>
              Thank you very much for signing up.
            </div> :
            <>
              <p className='text-light font-weight-bold '>Register now to be notified of our launch</p>
              <Form
                submit={submit}
                submitting={submitting}
              />
              <div className='mb-5'>
                <SocialIcon url='https://twitter.com/findapropertymu' network='twitter' bgColor='#343a40' fgColor='#ffffff' style={{ height: 30, width:30, marginRight: 10 }}  />
                <SocialIcon url='https://www.facebook.com/Findapropertymu' network='facebook' bgColor='#343a40' fgColor='#ffffff' style={{ height: 30, width: 30, marginRight: 10}}  />
                <SocialIcon url='https://www.instagram.com/Findapropertymu/' network='instagram' bgColor='#343a40' fgColor='#ffffff' style={{ height: 30, width: 30, marginRight: 10}} />
              </div>
            </>
          }
        </div>
      <div className='col' />
    </main>
  )
}

export default Main
