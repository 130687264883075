import { ErrorProps } from '../reducer/index.types'

export enum Actions {
  REGISTER_USER,
  REGISTER_ERROR,
  PENDING
}

export interface pendingAction {
  type: Actions.PENDING
}

export interface registeredAction {
  type: Actions.REGISTER_USER
}

export interface errorAction extends ErrorProps {
  type: Actions.REGISTER_ERROR
}

export type registrationTypes = pendingAction | registeredAction | errorAction

