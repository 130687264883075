import { Initial } from './index.types'
import { Actions, registrationTypes } from '../actions/actions.types'

export const InitialState: Initial = {
  submitting: false,
  registered: false,
  error: {},
  hasError: false
}

export const reducer = (
  state: Initial,
  action: registrationTypes
): Initial => {
  switch (action.type) {
    case Actions.PENDING:
      return {
        ...state,
        submitting: true
      }
    case Actions.REGISTER_USER:
      return {
        ...state,
        submitting: false,
        registered: true,
        error: {},
        hasError: false
      }
    case Actions.REGISTER_ERROR:
      return {
        ...state,
        submitting: false,
        registered: false,
        error: action.error,
        hasError: true
      }
    default:
      return state
  }
}
